class Language {
  constructor() {
    if (Language.instance) {
      return Language.instance;
    }

    this.languageId = undefined;

    this.languageDefinitions = {
      "networkIssue": {
        1: "AIDA ist derzeit nicht verfügbar. Kommt bald zurück.",
        2: "AIDA is out of service now. Will get back soon.",
        3: "AIDA è fuori servizio. Tornerà presto.",
        4: "AIDA est hors service pour le moment. Elle reviendra bientôt.",
        5: "AIDAは現在サービスを停止しています。 すぐに戻ってきます。",
        6: "AIDA está fuera de servicio. Volverá pronto.",
        7: "AIDA ไม่พร้อมให้บริการในขณะนี้ จะกลับมาเร็วๆ นี้",
        8: "AIDA ist derzeit nicht verfügbar. Kommt bald zurück."
      },
      "messagePlaceholder": {
        1: "Schreiben Sie eine Nachricht...",
        2: "Type a message...",
        3: "Scrivi un messaggio...",
        4: "Tapez un message...",
        5: "ネットワークの問題。 お待ちください...",
        6: "Escribe un mensaje...",
        7: "พิมพ์ข้อความ...",
        8: "Schreiben Sie eine Nachricht..."
      },
      "oneSessionAllowedPlaceholder": {
        1: "Nur eine Sitzung erlaubt...",
        2: "Only one session allowed...",
        3: "Solo una sessione consentita...",
        4: "Seule une session autorisée...",
        5: "セッションは1つだけ許可されます...",
        6: "Solo se permite una sesión...",
        7: "อนุญาตเฉพาะหนึ่งเซสชันเท่านั้น...",
        8: "Nur eine Sitzung erlaubt..."
      },// Service
      "greeting-f830f69d23b8224b512a0dc2f5aec974": {
        1: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Ich kann Ihnen bei allgemeinen Fragen zum ebuero Portal helfen, Termine vereinbaren oder sie bei Bedarf an einen Menschen weiterleiten. Sie machen es mir einfacher, sie zu verstehen, wenn sie in ganzen Sätzen mit mir sprechen.",
        2: "Hello, I'm Aida, a newly hatched chatbot and I still have a lot to learn. Please forgive me for any mistakes and feel free to give me feedback. I can help you with general questions about the ebuero portal, arrange appointments or forward you to a human if necessary. You make it easier for me to understand you if you speak to me in complete sentences.",
        3: "Ciao, sono Aida, una chatbot appena nata e ho ancora molto da imparare. Per favore perdonami per uno o due errori e sentiti libero di darmi un feedback. Posso aiutarti con domande generali sul portale ebuero, fissare appuntamenti o indirizzarti a un essere umano, se necessario. Mi facilitano la comprensione quando mi parlano con frasi complete.",
        4: "Bonjour, je m'appelle Aida, un chatbot nouvellement éclos et j'ai encore beaucoup à apprendre. Veuillez me pardonner une ou deux erreurs et n'hésitez pas à me faire part de vos commentaires. Je peux vous aider avec des questions générales sur le portail ebuero, organiser des rendez-vous ou vous orienter vers un humain si nécessaire. Ils me permettent de les comprendre plus facilement lorsqu'ils me parlent avec des phrases complètes.",
        5: "こんにちは、孵化したばかりのチャットボットであるアイダです。まだまだ学ぶことがたくさんあります。 1 つまたは 2 つの間違いをご容赦ください。お気軽にフィードバックをお寄せください。 ebuero ポータルに関する一般的な質問をお手伝いしたり、必要に応じて予約を手配したり、担当者を紹介したりできます。彼らが完全な文で話してくれると、私は理解しやすくなります。",
        6: "Hola, soy Aida, un chatbot recién nacido y todavía tengo mucho que aprender. Perdóname por uno o dos errores y no dudes en darme tu opinión. Puedo ayudarte con dudas generales sobre el portal ebuero, concertar citas o derivarte a un humano si es necesario. Me facilitan entenderlos cuando me hablan con frases completas.",
        7: "สวัสดี ฉันชื่อ Aida แชทบอทที่เพิ่งฟักออกมา และฉันยังมีอะไรให้เรียนรู้อีกมาก ผิดพลาดประการใดประการหนึ่งขออภัยไว้ ณ ที่นี้ และโปรดแสดงความคิดเห็นด้วย ฉันสามารถช่วยคุณตอบคำถามทั่วไปเกี่ยวกับพอร์ทัล ebuero นัดหมาย หรือส่งต่อคุณให้เจ้าหน้าที่ได้หากจำเป็น พวกเขาทำให้ฉันเข้าใจพวกเขาได้ง่ายขึ้นเมื่อพวกเขาพูดกับฉันด้วยประโยคที่สมบูรณ์",
        8: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Ich kann Ihnen bei allgemeinen Fragen zum ebuero Portal helfen, Termine vereinbaren oder sie bei Bedarf an einen Menschen weiterleiten. Sie machen es mir einfacher, sie zu verstehen, wenn sie in ganzen Sätzen mit mir sprechen."
      },// Sales
      "greeting-f830f69d23b8224b512a0dc2f5aec373": {
        1: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?",
        2: "Hello, I'm Aida, a newly hatched chatbot and I still have a lot to learn. Please forgive me for any mistakes and feel free to give me feedback. Can I help you?",
        3: "Ciao, sono Aida, una chatbot appena nata e ho ancora molto da imparare. Per favore perdonami per uno o due errori e sentiti libero di darmi un feedback. Posso aiutarla?",
        4: "Bonjour, je m'appelle Aida, un chatbot nouvellement éclos et j'ai encore beaucoup à apprendre. Veuillez me pardonner une ou deux erreurs et n'hésitez pas à me faire part de vos commentaires. Puis-je vous aider?",
        5: "こんにちは、孵化したばかりのチャットボットであるアイダです。まだまだ学ぶことがたくさんあります。 1 つまたは 2 つの間違いをご容赦ください。お気軽にフィードバックをお寄せください。いかがなさいましたか？",
        6: "Hola, soy Aida, un chatbot recién nacido y todavía tengo mucho que aprender. Perdóname por uno o dos errores y no dudes en darme tu opinión. ¿Puedo ayudarle?",
        7: "สวัสดี ฉันชื่อ Aida แชทบอทที่เพิ่งฟักออกมา และฉันยังมีอะไรให้เรียนรู้อีกมาก ผิดพลาดประการใดประการหนึ่งขออภัยไว้ ณ ที่นี้ และโปรดแสดงความคิดเห็นด้วย ฉันช่วยคุณได้ไหม?",
        8: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?"
      }, // Candoo
      "greeting-f830f69d23b8224b512a0dc2f5aec174": {
        1: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?",
        2: "Hello, I'm Aida, a newly hatched chatbot and I still have a lot to learn. Please forgive me for any mistakes and feel free to give me feedback. Can I help you?",
        3: "Ciao, sono Aida, una chatbot appena nata e ho ancora molto da imparare. Per favore perdonami per uno o due errori e sentiti libero di darmi un feedback. Posso aiutarla?",
        4: "Bonjour, je m'appelle Aida, un chatbot nouvellement éclos et j'ai encore beaucoup à apprendre. Veuillez me pardonner une ou deux erreurs et n'hésitez pas à me faire part de vos commentaires. Puis-je vous aider?",
        5: "こんにちは、孵化したばかりのチャットボットであるアイダです。まだまだ学ぶことがたくさんあります。 1 つまたは 2 つの間違いをご容赦ください。お気軽にフィードバックをお寄せください。いかがなさいましたか？",
        6: "Hola, soy Aida, un chatbot recién nacido y todavía tengo mucho que aprender. Perdóname por uno o dos errores y no dudes en darme tu opinión. ¿Puedo ayudarle?",
        7: "สวัสดี ฉันชื่อ Aida แชทบอทที่เพิ่งฟักออกมา และฉันยังมีอะไรให้เรียนรู้อีกมาก ผิดพลาดประการใดประการหนึ่งขออภัยไว้ ณ ที่นี้ และโปรดแสดงความคิดเห็นด้วย ฉันช่วยคุณได้ไหม?",
        8: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?"
      }, // BSF
      "greeting-f830f69d23b8224b512a0dc2f5aec374": {
        1: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?",
        2: "Hello, I'm Aida, a newly hatched chatbot and I still have a lot to learn. Please forgive me for any mistakes and feel free to give me feedback. Can I help you?",
        3: "Ciao, sono Aida, una chatbot appena nata e ho ancora molto da imparare. Per favore perdonami per uno o due errori e sentiti libero di darmi un feedback. Posso aiutarla?",
        4: "Bonjour, je m'appelle Aida, un chatbot nouvellement éclos et j'ai encore beaucoup à apprendre. Veuillez me pardonner une ou deux erreurs et n'hésitez pas à me faire part de vos commentaires. Puis-je vous aider?",
        5: "こんにちは、孵化したばかりのチャットボットであるアイダです。まだまだ学ぶことがたくさんあります。 1 つまたは 2 つの間違いをご容赦ください。お気軽にフィードバックをお寄せください。いかがなさいましたか？",
        6: "Hola, soy Aida, un chatbot recién nacido y todavía tengo mucho que aprender. Perdóname por uno o dos errores y no dudes en darme tu opinión. ¿Puedo ayudarle?",
        7: "สวัสดี ฉันชื่อ Aida แชทบอทที่เพิ่งฟักออกมา และฉันยังมีอะไรให้เรียนรู้อีกมาก ผิดพลาดประการใดประการหนึ่งขออภัยไว้ ณ ที่นี้ และโปรดแสดงความคิดเห็นด้วย ฉันช่วยคุณได้ไหม?",
        8: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?"
      },
      "greeting-fallback": {
        1: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?",
        2: "Hello, I'm Aida, a newly hatched chatbot and I still have a lot to learn. Please forgive me for any mistakes and feel free to give me feedback. Can I help you?",
        3: "Ciao, sono Aida, una chatbot appena nata e ho ancora molto da imparare. Per favore perdonami per uno o due errori e sentiti libero di darmi un feedback. Posso aiutarla?",
        4: "Bonjour, je m'appelle Aida, un chatbot nouvellement éclos et j'ai encore beaucoup à apprendre. Veuillez me pardonner une ou deux erreurs et n'hésitez pas à me faire part de vos commentaires. Puis-je vous aider?",
        5: "こんにちは、孵化したばかりのチャットボットであるアイダです。まだまだ学ぶことがたくさんあります。 1 つまたは 2 つの間違いをご容赦ください。お気軽にフィードバックをお寄せください。いかがなさいましたか？",
        6: "Hola, soy Aida, un chatbot recién nacido y todavía tengo mucho que aprender. Perdóname por uno o dos errores y no dudes en darme tu opinión. ¿Puedo ayudarle?",
        7: "สวัสดี ฉันชื่อ Aida แชทบอทที่เพิ่งฟักออกมา และฉันยังมีอะไรให้เรียนรู้อีกมาก ผิดพลาดประการใดประการหนึ่งขออภัยไว้ ณ ที่นี้ และโปรดแสดงความคิดเห็นด้วย ฉันช่วยคุณได้ไหม?",
        8: "Hallo, ich bin Aida, ein frisch geschlüpfter Chatbot und muss noch ganz viel lernen. Bitte verzeihen sie mir den einen oder anderen Fehler und geben sie mir gerne Feedback. Kann ich Ihnen weiterhelfen?"
      },
    }

    Language.instance = this;
  }

  static getInstance() {
    if (!Language.instance) {
      Language.instance = new Language();
    }
    return Language.instance;
  }

  setLanguage(languageId) {
    this.languageId = parseInt(languageId);
  }

  getText(key) {

    if (this.languageDefinitions.hasOwnProperty(key)) {
      if (this.languageDefinitions[key].hasOwnProperty(this.languageId)) {
        return this.languageDefinitions[key][this.languageId];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

}

export default Language;
