import React, { Component } from 'react';
import networkErrorIcon from '../Images/no-internet.png'
import Language from '../Language';
class ConnectionError extends Component {
    render() {
      const language = Language.getInstance();
      const message = language.getText("networkIssue");
      return <div className="connection-error"> 
      <img alt="Connection error" src={networkErrorIcon}></img>
      <p>{message}</p>
      </div>;
    }
}
export default ConnectionError;